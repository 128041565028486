import React from 'react';

const BannerLanding = props => (
  <section id="banner" className="style2">
    <div className="inner">
      <header className="major">
        <h1>Coaching Plans</h1>
      </header>
      <h2>Why are there no cookie cutter plans?</h2>
      <div className="content">
        <p>
          I don’t have a one size fits all training plan. In your initial
          questionnaire, I’ll ask about your current  training plan and cardio
          regimen. I’ll ask you about what’s been working, and what’s not been
          working for you thus far. I’ll create a plan based on your needs,
          scientific research, and what you’ve already done, to create the
          ultimate in “plateau-busting” training methodology.
        </p>
      </div>
    </div>
  </section>
);

export default BannerLanding;
