import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import BannerLanding from '../components/BannerLanding';

import firstTile from '../assets/images/firstTile.jpeg';
import secondTile from '../assets/images/secondTile.jpeg';
import thirdTile from '../assets/images/thirdTile.jpeg';

const Plans = props => (
  <Layout>
    <Helmet>
      <title>Coaching Plans</title>
      <meta name="description" content="Coaching Plans" />
    </Helmet>

    <BannerLanding />

    <div id="main">
      <section className="spotlights">
        <section id="personal">
          <Link className="image">
            <img src={firstTile} alt="1 on 1 Personal Training" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>1 on 1 Personal Training</h3>
              </header>
              <p>
                Whether I come to you or you come to me, I will supply the
                equipment, the exercise routine, and the motivation! No extra
                gym fees, travel time, or distractions. Just a workout
                customized for you!
              </p>
              <div>
                <h4>
                  <u>Monthly Packages</u>
                </h4>
                <p>
                  <i>(All plans include the following.)</i>
                </p>
                <div className="table-wrapper">
                  <table className="alt">
                    <tbody>
                      <tr>
                        <td>Full Fitness Assessment</td>
                        <td>
                          <b>$75 Value</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Customized Workout Plans</td>
                        <td>
                          <b>$400 Value</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Nutritional Protocol</td>
                        <td>
                          <b>$75 Value</b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Access to my Personal Training App For Extra Virtual
                          Workouts, Recipes, Motivation
                        </td>
                        <td>
                          <b>$400 Value</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Cardio And Stretching Protocols</td>
                        <td>
                          <b>$75 Value</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Personal Q&amp;A Response</td>
                        <td>
                          <b>$75 Value</b>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td>
                          <b>$1,110.00 Value</b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div>
                <h4>
                  <u>Plans</u>
                </h4>
                <h5>
                  <u>Gold</u>
                </h5>
                <div className="table-wrapper">
                  <table className="alt">
                    <tbody>
                      <tr>
                        <td>1x individual session a week, 60 minutes</td>
                        <td>
                          <b>Low Monthly Investment of $480</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h5>
                  <u>Platinum</u>
                </h5>
                <div className="table-wrapper">
                  <table className="alt">
                    <tbody>
                      <tr>
                        <td>2x individual session a week, 30/60 minutes</td>
                        <td>
                          <b>Low Monthly Investment of $480/$960</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h5>
                  <u>Customized</u>
                </h5>
                <p>
                  Do you have a partner that wants to work with you, need a
                  customized nutrition plan to add, or do you want to work out
                  more with me? Message me for more details!
                </p>
              </div>
              <ul className="actions">
                <li>
                  <Link to="/contact?plan=Personal" className="button">
                    Book A Consult
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section id="virtual">
          <Link className="image">
            <img src={secondTile} alt="Virtual Training" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Virtual Training</h3>
              </header>
              <p>
                Do you have a busy lifestyle, travel for work, or want to keep
                your distance from other people? We can do our workouts together
                online <i>or</i> I can write up a monthly plan for you, with
                weekly check-in’s to keep you on track! It’s safe, efficient,
                and effective!
              </p>
              <div>
                <h4>Monthly Packages</h4>
              </div>
              <div>
                <h4>
                  <u>Plans</u>
                </h4>
                <h5>
                  <u>Silver</u>
                </h5>
                <div className="table-wrapper">
                  <table className="alt">
                    <tbody>
                      <tr>
                        <td>
                          Access to my Personal Training App For Virtual
                          Workouts, Recipes, Motivation
                        </td>
                        <td>
                          <b>$400 Value</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Nutrition Protocols</td>
                        <td>
                          <b>$75 Value</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Personal Q&amp;A Response</td>
                        <td>
                          <b>$75 Value</b>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>
                          <b>Low Monthly Investment of $197</b>
                        </td>
                        <td>
                          <b>$550.00 Value </b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <h5>
                  <u>Gold</u>
                </h5>
                <div className="table-wrapper">
                  <table className="alt">
                    <tbody>
                      <tr>
                        <td>Monthly Customized Workout Plan</td>
                        <td>
                          <b>$400 Value</b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Access to my Personal Training App For Extra Virtual
                          Workouts, Recipes, Motivation
                        </td>
                        <td>
                          <b>$400 Value</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Nutrition Protocols</td>
                        <td>
                          <b>$75 Value</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Cardio and Stretching Protocols</td>
                        <td>
                          <b>$75 Value</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Personal Q&amp;A Response</td>
                        <td>
                          <b>$75 Value</b>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>
                          <b>Low Monthly Investment of $297</b>
                        </td>
                        <td>
                          <b>$1,025.00 Value</b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <h5>
                  <u>Platinum</u>
                </h5>
                <div className="table-wrapper">
                  <table className="alt">
                    <tbody>
                      <tr>
                        <td>Monthly Customized Workout Plan</td>
                        <td>
                          <b>$400 Value</b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Access to my Personal Training App For Extra Virtual
                          Workouts, Recipes, Motivation
                        </td>
                        <td>
                          <b>$400 Value</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Nutrition Plan with Macro Nutrient Breakdown</td>
                        <td>
                          <b>$150 Value</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Cardio and Stretching Protocols</td>
                        <td>
                          <b>$75 Value</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Personal Q&amp;A Response</td>
                        <td>
                          <b>$75 Value</b>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>
                          <b>Low Monthly Investment of $397</b>
                        </td>
                        <td>
                          <b>$1,100.00 Value</b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <ul className="actions">
                <li>
                  <Link to="/contact?plan=Virtual" className="button">
                    Book A Consult
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section id="group">
          <Link className="image">
            <img src={thirdTile} alt="Partner/Small Group Training" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Partner/Small Group Training</h3>
              </header>
              <p>
                Do you have a spouse or friend you want to workout with? Do you
                want to save a little money and still get a great workout in?
                Working in groups gives you all the same benefits at half the
                cost!
              </p>
              <div>
                <h4>
                  <u>Partner Training Monthly Package (2 people)</u>
                </h4>
                <h5>
                  <u>Gold</u>
                </h5>
                <div className="table-wrapper">
                  <table className="alt">
                    <tbody>
                      <tr>
                        <td>1x session a week, 60 minutes</td>
                        <td>
                          <b>$275/each</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h5>
                  <u>Platinum</u>
                </h5>
                <div className="table-wrapper">
                  <table className="alt">
                    <tbody>
                      <tr>
                        <td>2x session a week, 60 minutes</td>
                        <td>
                          <b>$575/each</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <h4>
                  <u>Small Group Training Monthly Package (3-5 people)</u>
                </h4>
                <h5>
                  <u>Gold</u>
                </h5>
                <div className="table-wrapper">
                  <table className="alt">
                    <tbody>
                      <tr>
                        <td>1x session a week, 60 minutes</td>
                        <td>
                          <b>$250/each</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h5>
                  <u>Platinum</u>
                </h5>
                <div className="table-wrapper">
                  <table className="alt">
                    <tbody>
                      <tr>
                        <td>2x session a week, 60 minutes</td>
                        <td>
                          <b>$550/each</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <ul className="actions">
                <li>
                  <Link to="/contact?plan=Group" className="button">
                    Book A Consult
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
);

export default Plans;
